import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

// Models
import User from '../../../../../../models/v2/user';

// Base
import Button from '../../../../base/button';
import Card from '../../../../base/card';
import Col from '../../../../base/col';
import Row from '../../../../base/row';

export default class UserType extends React.Component {
  static propTypes = {
    user: PropTypes.object.isRequired,
    onChangeUserType: PropTypes.func.isRequired,
    onNext: PropTypes.func.isRequired,
  }

  render() {
    const { user, onChangeUserType, onNext } = this.props;

    return (
      <Row noMargin>
        <Col s={12}>
          <div className='margin-bottom-5 center-align'>I am a</div>
        </Col>
        <Col s={12}>
          <Row className='flex flex-row margin-bottom-15'>
            {
              Object.keys(User.userTypeData).map((type, i) => (
                <Col s={6} className='margin-right-5' key={i} noPadding>
                  <Card
                    className={classNames('justify-center vertical-align sm-font height-100pct', {
                      'primary-color primary-border': user.user_type === type,
                      'gray-900': user.user_type !== type,
                    })}
                    isSelected={user.user_type === type}
                    onClick={() => onChangeUserType(type)}
                    hoverable
                  >
                    <div className='center-align padding-top-15'>
                      <div className='sm-font margin-bottom-5'>
                        {User.userTypeData[type].name}
                      </div>
                      <div className='xxsm-font'>
                        {User.userTypeData[type].description}
                      </div>
                      <img
                        src={User.userTypeData[type].img}
                        className='width-130 margin-top-15 margin-bottom-5'
                      />
                    </div>
                  </Card>
                </Col>
              ))
            }
          </Row>
        </Col>
        <Col s={12}>
          <Button
            text='Next'
            onClick={onNext}
            block
            disabled={!user.user_type}
          />
        </Col>
      </Row>
    )
  }
}