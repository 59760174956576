import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import PasswordChecklist from 'react-password-checklist';
import PasswordStrengthBar from 'react-password-strength-bar';

export default class PasswordStrengthChecklist extends React.Component {
  static propTypes = {
    password: PropTypes.string,
  }

  render() {
    const { password } = this.props;

    return (
      <React.Fragment>
        <PasswordStrengthBar
          password={password}
          className={classNames('password-strengthbar', {
            'hide': password.length === 0,
            'block': password.length > 0
          })}
          scoreWordClassName='xxsm-font capitalize'
          scoreWordStyle={{ textAlign: 'left' }}
        />
        <PasswordChecklist
          rules={[ 'minLength', 'lowercase', 'capital', 'number', 'specialChar' ]}
          minLength={8}
          value={password}
          iconSize={10}
          className={classNames('password-checklist xxsm-font margin-bottom-20', {
            'hide': password.length === 0,
            'block': password.length > 0
          })}
          messages={{
            minLength: 'At least 8 characters',
            lowercase: 'Lowercase',
            capital: 'Uppercase',
            number: 'Number',
            specialChar: 'Special character',
          }}
        />
      </React.Fragment>
    )
  }
}