import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

export default class Checkbox extends React.Component {
  static propTypes = {
    id: PropTypes.string,
    name: PropTypes.string,
    label: PropTypes.string,
    className: PropTypes.string,
    labelClassName: PropTypes.string,
    defaultChecked: PropTypes.bool,
    tabIndex: PropTypes.string,
    errorMessage: PropTypes.any,
    disabled: PropTypes.bool,
    checked: PropTypes.bool,
    value: PropTypes.oneOfType([PropTypes.bool, PropTypes.string, PropTypes.number]),
    noMargin: PropTypes.bool,
    onChange: PropTypes.func,
    required: PropTypes.bool,
    readOnly: PropTypes.bool,
    inline: PropTypes.bool,
    circle: PropTypes.bool,
    data: PropTypes.string,
  }

  static defaultProps = {
    id: '',
    tabIndex: '',
    classNames: '',
    name: '',
    onChange: () => { },
    noMargin: false,
    defaultChecked: false,
    disabled: false,
    required: false,
    readOnly: false,
    circle: false,
    inline: false,
    data: '',
  }

  constructor(props) {
    super(props);
    this.state = {
      checked: props.checked || props.defaultChecked
    };
  }

  static getDerivedStateFromProps(props, state) {
    if (props.checked !== undefined && props.checked !== state.checked) {
      return {
        checked: props.checked
      };
    }
    return null;
  }

  get classNames() {
    const { className, noMargin, inline } = this.props;
    return classNames('input-field', {
      [className]: className,
      'inline-block': inline,
      'margin-bottom-0': noMargin,
    });
  }

  get inputClassNames() {
    return classNames({
      'filled-in': true,
      'is-circle': this.props.circle,
      'is-read-only': this.props.readOnly
    });
  }

  handleChange = (e) => {
    e.persist();
    const { onChange } = this.props;
    const checked = e.target.checked;

    this.setState({
      checked
    }, () => onChange(e, checked));
  }

  handleKeyDown = (e) => {
    // DO NOT PREVENT DEFAULT AT THE TOP LEVEL!
    e.persist();

    switch (e.key) {
      case ' ':
        this.handleChange(e);
        break;
      // Cannot respond to Enter due to it submitting forms
      default:
        break;
    }
  }

  render() {
    const { label, id, name, errorMessage, tabIndex, disabled, required, value, data, labelClassName } = this.props;
    const { checked } = this.state;

    return (
      <div className={this.classNames}>
        <input {...{
          name,
          id,
          value,
          tabIndex,
          disabled,
          required,
          checked: !!checked,
          type: 'checkbox',
          className: this.inputClassNames,
          'data-data': data,
          onChange: this.handleChange,
          onFocus: () => this.setState({ focused: true }),
          onBlur: () => this.setState({ focused: false }),
          onKeyDown: this.handleKeyDown,
        }} />
        {
          !!label &&
          <label htmlFor={id} className={labelClassName}>
            {label}
          </label>
        }
        {
          !!errorMessage &&
          <div className='error-message'>{errorMessage}</div>
        }
      </div>
    );
  }
}
