import Model from './model';
import Event from './event';
import { faCloudUpload, faClipboardList, faPalette, faUsers, faBullhorn, faCalculatorAlt, faCogs, faArchive, faChartLine } from '@fortawesome/pro-solid-svg-icons';

export default class Opportunity extends Model {
  static NAME = 'opportunity';
  static URL = '/api/v2/opportunities';

  static contractTypes = {
    'full_time': 'Full Time',
    'part_time': 'Part Time'
  };

  static directHires = {
    'contract': 'Contract',
    'permanent': 'Permanent'
  };

  static categories = {
    it: 'IT',
    management: 'Management',
    design: 'Design',
    hr: 'HR',
    marketing: 'Marketing',
    finance: 'Finance',
    operations: 'Operations',
    sales: 'Sales',
    other: 'Other',
  };

  static categoryIcons = {
    it: faCloudUpload,
    management: faClipboardList,
    design: faPalette,
    hr: faUsers,
    marketing: faBullhorn,
    finance: faCalculatorAlt,
    operations: faCogs,
    sales: faChartLine,
    other: faArchive,
  };

  // == Instance Methods =====================================================
  isPublished() {
    return !!this.published_at;
  }

  trackView() {
    const data = {
      event_type: 'opportunity_viewed',
      event_data: JSON.stringify({
        client_request_id: this.client_request_id,
        opportunity_id: this.id,
        opportunity_title: this.title,
      }),
      description: 'Viewed Opportunity',
    };
    (new Event(data)).save()
  }

  canShare() {
    if (this.isInternal()) {
      return (!this.is_closed && this.published);
    }
    return !this.is_closed;
  }

  isInternal() {
    return this.client_request_id;
  }

  companyName() {
    return this.isInternal() ? this.company_display_name : this.jobspikr_company_name;
  }

  shareText() {
    return `${this.companyName()} has posted a ${this.title} opportunity`;
  }

  shareUrl() {
    return `${window.location.origin}/opportunities/${this.id}`;
  }

  encodedShareUrl() {
    return `${this.shareUrl()}`.replace(':', '%3A').replaceAll('/', '%2F');
  }

  shareFacebookUrl() {
    return `http://www.facebook.com/sharer/sharer.php?u=${this.encodedShareUrl()}`;
  }

  shareLinkedinUrl() {
    return `https://www.linkedin.com/cws/share?url=${this.encodedShareUrl()}`;
  }

  shareTwitterUrl() {
    return `https://twitter.com/intent/tweet?text=${this.shareText()}&url=${this.encodedShareUrl()}`;
  }
}